import * as React from "react"
import { graphql, PageProps } from "gatsby"
import { ChainPageQuery, Store, File } from "../../graphql-types"
import Layout from "../../components/layout"
import StoreList from "../../partials/store/store-list"
import Container from "../../components/container"
import useVarieties from "../../hooks/use-varieties"
import useLocations from "../../hooks/use-locations"
import Hero from "../../components/hero"

export default function ChainPage({ data }: PageProps<ChainPageQuery>) {
  const locations = useLocations()
  const varieties = useVarieties()

  return (
    <Layout noGap title={`${data.chain.title} - Japan`}>
      <div className={"flex w-full flex-col gap-8"}>
        <Hero
          title={data.chain.title}
          heroImage={data.heroImage as File}
          compact={data.chain.body === ""}
        >
          {data.chain.body && (
            <div
              className={"justify-center prose text-white"}
              dangerouslySetInnerHTML={{ __html: data.chain.body }}
            />
          )}
        </Hero>
        <Container className={"justify-center"}>
          <StoreList
            locations={locations}
            varieties={varieties}
            stores={data.chain.stores as Store[]}
          />
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ChainPage($id: String!) {
    chain(id: { eq: $id }) {
      title
      slug
      body
      stores {
        ...StoreCommon
        ...StoreVarieties
        ...StoreChain
        ...StoreTileImages
        ...StoreLocation
      }
    }
    heroImage: file(relativePath: { eq: "opshop.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
